$debug: #ccc;
$info: #cab999;
$warning: #f6d128;
$success: #32c518;
$error: #bd122e;
$nav-line: #dadada;
$hover-color: #015184;
$input: #a5a5a4;
$main: #ce1126;
$black: #000;
$white: #fff;
$title: #333;
$input-text: #666;
$border: #dadada;
$bg1: #f4f4f4;
$gray: #444;

$width-large: 14vw;
$width-middle: 4.66667vw;
$width-small: 1em;
