@use 'variable' as *;
@use 'fonts' as *;
@use 'mixin';

html {
    font-family: 'Roboto', sans-serif;
    font-size: 95%;
}

ul,
ol {
    padding-left: 2rem;
}

h1 {
    @include mixin.font-set(2.31em, $white, bold);
}

h2 {
    @include mixin.font-set(1.4em, $hover-color, 300);

    text-align: left;
}

h3 {
    @include mixin.font-set(1.25em, $title, bold);
}

h1,
h2,
h3 {
    line-height: 1.33em;
    margin-bottom: 0.66em;
}

a {
    @include mixin.linx($hover-color, $title, $title);

    color: $title;
    text-align: center;
    text-decoration: underline;

    &:hover {
        color: $hover-color;
    }
}

p {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.5;
    margin-bottom: 0.66em;
}

strong {
    font-weight: bold;
}

pre {
    @include mixin.font-set(0.8em, $input-text, 300);

    padding: 0 0 1.6em;
}

code {
    @include mixin.font-set(1.2em, $title, 300);
}

/*
*
* Homepage
*
*/
.row-box,
.container,
.aldryn-newsblog-pagination {
    padding: 0 $width-large;

    @media (max-width: 73em) {
        padding: 0 $width-middle;
    }

    @media (max-width: 55em) {
        padding: 0 $width-small;
    }

    a {
        color: $title;
        text-decoration: underline;
    }
}

.row-box {
    margin-top: 4em;
    text-align: center;
}

body.show-body {
    background: $nav-line;
}

#pageContent.hide-content {
    display: none;
}

.clear {
    clear: both;
    margin-bottom: 2em;

    a {
        @include mixin.font-set(1em, $title, 300);
        @include mixin.linx($hover-color, none, none);

        display: block;
        text-align: center;
        text-decoration: underline;
    }
}

/*
*
* text-page
*
*/
.container {
    padding-top: 2em;

    h1 {
        @include mixin.font-set(1.8em, $hover-color, 300);
    }

    h2 {
        @include mixin.font-set(1.33em, $hover-color, 300);

        padding-top: 1em;
    }

    h3 {
        @include mixin.font-set(1.2em, $hover-color, 300);

        padding-top: 1em;
    }

    h4 {
        @include mixin.font-set(1.4em, $title, 300);

        line-height: 1.5;
        padding: 1em 0 3em;
    }

    a {
        @include mixin.linx($hover-color, none, none);

        color: $title;
        text-decoration: underline;
    }

    ul {
        margin: 0 0 2em 1.5em;

        li {
            list-style-image: url('../img/arrow-list.png');

            p {
                margin-bottom: 1em;
            }

            a,
            code {
                line-height: 1.5;
            }

            code {
                @media (max-width: 55em) {
                    font-size: 0.8em;
                }
            }
        }
    }

    .border-text,
    .news-version {
        border: 0.2em solid $border;
        padding: 0 2em 2em;

        .add-underline {
            border-bottom: 0.1em solid $border;
            display: block;
            padding-bottom: 1.2em;
            text-align: left;
        }

        pre {
            padding: 0 0 1em;
        }

        p:last-of-type .add-underline {
            margin-bottom: 3em;
        }
    }

    .news-version {
        border: 0;
        padding: 0;

        p:last-of-type .add-underline {
            border: 0;
        }
    }

    .col-lg-9 {
        padding-left: 3em;
    }

    table {
        @include mixin.font-set(1em, $title, 300);

        border: 1px solid $debug;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 1em 0;

        th {
            background-color: $bg1;
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            font-weight: bold;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }

        td {
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }
    }

    .row.column-reverse {
        @media (max-width: 62em) {
            flex-direction: column-reverse;

            .col-lg-9 {
                padding-left: 1em;
            }
        }
    }
}

.col-lg-4,
.col-lg-3,
.col-lg-2 {
    padding-bottom: 3em;

    img {
        height: 9em;
    }

    a:hover {
        color: $hover-color;
    }
}

.row-box-bg {
    background: $gray;
    color: $white;
    padding-bottom: 25px;
    padding-top: 25px;

    .row {
        justify-content: space-between;
        margin-left: 0;

        @media (max-width: 55em) {
            margin: 0;
        }
    }

    h1 {
        @include mixin.font-set(2.31em, $white, bold);
    }

    a {
        color: $white;
    }

    .col-lg-8 {
        padding: 70px 30px 90px 0;

        p {
            @include mixin.font-set(1.5em, $white, 300);
        }

        .btn-light {
            @include mixin.font-set(1.5em, $white, bold);

            background-color: transparent;
            border: 0.13333em solid $white;
            border-radius: 0.5em;
            display: inline-block;
            margin-top: 2em;
            padding: 0.22em 1.5em;
            text-transform: uppercase;
            transition: all 0.1s linear;

            &:hover {
                background-color: $white;
                color: $main;
            }
        }
    }

    .col-lg-4 {
        @include mixin.flex-gsw(0, 0, 364px);

        @media (max-width: 62em) {
            @include mixin.flex-gsw(1, 0, auto);

            margin: 0 0 2em;
            padding: 0;
        }

        p {
            @include mixin.font-set(1em, $white, 300);
        }

        > a {
            @include mixin.font-set(1em, $white, 300);

            display: block;
            line-height: 1.5;
            text-align: right;
            text-decoration: underline;

            &::after {
                content: ' »';
            }
        }

        .author {
            a {
                @include mixin.font-set(1em, $white, 300);

                text-decoration: underline;
            }
        }

        .border-text {
            border: 0.12em solid $border;
            padding: 2em;

            .txt-muted {
                @include mixin.font-set(1.2em, $white, 300);

                display: inline-block;
                line-height: 1.33em;
                margin-bottom: 0.66em;
                text-decoration: underline;
            }

            p,
            .news-title-small {
                @include mixin.font-set(1em, $white, 300);
            }

            .news-title-small {
                text-decoration: underline;
            }
        }

        .aldryn-newsblog-article h2 a {
            @include mixin.font-set(1.2em, $white, 300);

            text-decoration: underline;
        }

        .image.pull-left {
            img {
                height: auto;
            }
        }
    }
}

body.show-body .container,
body.show-body footer {
    display: none;
}

body.show-body #page-navigation {
    display: block;
}

@media (min-width: 1200px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}
