@use 'variable' as *;
@use 'mixin';

.menu-icon {
    display: none;
    position: absolute;
    right: 1em;
    top: 3.5em;
}

#closeMenu {
    display: none;
}

.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1em $width-large;

    a {
        text-align: left;
    }

    .logo {
        max-width: 187px;
        padding: 1.5em 0;
        width: 187px;
    }

    .search-form {
        display: flex;

        .search-input {
            appearance: none;
            border: 1px solid $input;
            border-right: 0;
            color: $input-text;
            padding: 9px 5px 9px 10px;
            transition: all 0.3s linear;
            width: 165px;
        }

        #search-button {
            appearance: none;
            background: $white;
            border: 1px solid $input;
            border-left: 0;
            cursor: pointer;
            padding-top: 5px;
            width: 35px;

            svg {
                fill: $input;
            }

            &:hover {
                background: $input;
            }

            &:hover svg {
                fill: $white;
            }
        }
    }
}

#page-navigation {
    border-bottom: 0.2em solid $nav-line;
    border-top: 0.2em solid $nav-line;
    padding: 0 $width-large;

    .active {
        box-shadow: 0 0.2em 0 0 $main;
    }

    li {
        display: inline-block;
        line-height: 3;
        margin-right: 1em;

        a {
            @include mixin.font-set( 1em, $title, bold);

            &:hover {
                color: $main;
            }
        }
    }
}

.is-homepage {
    .menu-icon {
        display: none;
    }

    #page-navigation {
        border-bottom: 0;
        border-top: 0;
        padding-bottom: 0;
        padding-top: 0;

        .active {
            box-shadow: 0 0.4em 0 0 $main;
        }
    }
}

ul#page-navigation > li.active a {
    color: $main;
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    .page-header {
        margin: 1em $width-middle;
    }

    #page-navigation {
        padding: 0 $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/

@media (max-width: 55em) {
    .page-header {
        align-items: normal;
        flex-direction: column;
        margin: $width-small;

        .search-form {
            .search-input {
                font-size: 1.2em;
                width: calc(100% - 35px);
            }
        }
    }

    #page-navigation {
        border: 0;
        display: none;
        margin: 1em;
        padding: 0;

        .active {
            box-shadow: -0.2em 0 0 0 $main;
        }

        li {
            display: block;
            line-height: 3;
            margin-right: 1em;
            padding: 0 0 0 1em;

            a {
                @include mixin.font-set( 1em, $title, bold);
            }
        }
    }

    .is-homepage {
        #page-navigation {
            display: block;

            .active {
                box-shadow: -0.4em 0 0 0 $main;
            }
        }
    }

    #openMenu.hide-icon {
        display: none;
    }

    #closeMenu.show-icon {
        display: block;
    }

    .menu-icon {
        display: block;
    }
}
