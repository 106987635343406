/*
*
* aldryn newsblog
*
*/
.djangocms-newsblog-content {
    margin: 0 14vw;
    padding: 2em 0;
}

.aldryn {
    .pager {
        display: flex;
        justify-content: space-between;
        margin-left: 0;

        li {
            line-height: 1.5;
            list-style-image: none;

            > span {
                padding: 1em 0.66em;
            }

            span[aria-hidden='true'] {
                position: relative;
                top: -3px;
            }
        }

        .previous,
        .next {
            > span {
                opacity: 0;
            }
        }

        a {
            padding: 1em 0.66em;
            text-decoration: none;
        }
    }

    .lead {
        font-size: 1em;
    }
}

.aldryn-newsblog-detail .aldryn-newsblog-article h2 {
    font-size: 200%;
}

.aldryn-newsblog-pager ul.pager {
    margin-top: 1em;

    li {
        float: none;
        margin: 0;
    }
}
