@use 'variable' as *;
@use 'mixin';

/*
*
* aldryn-newsblog-pagination
*
*/
.djangocms-newsblog-content {
    margin-top: 1em;

    ul.pagination {
        li {
            display: inline-block;
            margin-right: 1em;

            a,
            span {
                @include mixin.font-set(1em, $title, 300);

                border: 1px solid $title;
                display: inline-block;
                line-height: 1.55em;
                min-width: 1.55em;
                padding: 0.2em 0.4em;
            }

            a span {
                border: 1px solid transparent;
                line-height: 1;
            }

            a:hover {
                border: 1px solid $error;
                color: $error;
            }
        }

        li.active {
            span {
                border: 1px solid transparent;
            }
        }
    }
}
