
@use 'variable' as *;
@use 'mixin';

footer {
    align-items: center;
    border-top: 0.2em solid $nav-line;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 2em 0 3em;
    padding: 0 $width-large;
    padding-top: 6px;

    ul {
        display: inline-block;
        margin-top: 10px;

        li {
            display: inline-block;

            a {
                @include mixin.font-set(1em, $title, 300);
                @include mixin.linx($hover-color, none, none );

                line-height: 1.5;
                margin-right: 0.5em;
            }

            &::after {
                color: $nav-line;
                content: " |";
                margin-right: 0.5em;
            }

            &:first-of-type::after {
                content: ":";
                font-weight: bold;
                margin-left: -0.5em;
            }

            &:last-of-type::after {
                content: "";
            }

            &:first-of-type a {
                font-weight: bold;
            }
        }
    }

    .footer-logo-box {
        @include mixin.flex-gsw(0, 0, 200px);

        margin-left: 3em;

        .footer-logo {
            align-items: center;
            display: flex;
            margin-top: 0.577em;

            span {
                padding-right: 0.33em;
                transform: translate(0, -2px);
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    footer {
        padding: 10px $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    footer {
        flex-direction: column;
        margin: 1em 0 0;
        padding: 10px $width-small;

        .footer-logo-box {
            @include mixin.flex-gsw(0, 0, auto);

            margin: 1em 0 3em;
        }
    }
}
